import * as THREE from 'three';
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer.js';
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass.js';
import { ClearPass } from 'three/examples/jsm/postprocessing/ClearPass.js';
import { MaskPass, ClearMaskPass } from 'three/examples/jsm/postprocessing/MaskPass.js';
import { ShaderPass } from 'three/examples/jsm/postprocessing/ShaderPass.js';
import { CopyShader } from 'three/examples/jsm/shaders/CopyShader.js'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js'
import {Pane} from 'tweakpane';

var sizes, pane, canvas, camera, renderer, composer, renderTarget, numDimension, isCelebratory, isFarAway;

const shapeNames = ['square', 'dot', 'rounded', 'triangle', 'squiggle', 'kid'];
const presets = [
    {name: 'comingsoon', numberMask: false, rotateNums: false, threedee: false, bgColor: 0xFFFFFF, shapeColor: 0x0D0A04, zoom: 1.5, mode: 0, shape: 'square', columns: 55, rows: 55, shapeWidth: 1, shapeHeight: 1, speed: 0.01, freq: 0.02, amp: 1.00, scale: 0.1, rainbow: false, regular: true, sticky: false, explosion: false, bigWarp: true},
    {name: 'nav', numberMask: false, rotateNums: false, threedee: false, bgColor: 0x0d0a04, shapeColor: 0x1d1d1d, zoom: 1, mode: 0, shape: 'dot', columns: 50, rows: 50, shapeWidth: 1, shapeHeight: 1, speed: 0.1, freq: 0.09, amp: 0.2, scale: 0.7, rainbow: false, regular: true, sticky: false, explosion: false, bigWarp: false},
    {name: 'bloopy', numberMask: false, rotateNums: false, threedee: false, bgColor: 0x0d0a04, shapeColor: 0x1d1d1d, zoom: 1.5, mode: 0, shape: 'dot', columns: 250, rows: 250, shapeWidth: 0.5, shapeHeight: 0.5, speed: 0.1, freq: 0.6, amp: 0.2, scale: 0.1, rainbow: false, regular: true, sticky: false, explosion: true, bigWarp: false},
    {name: 'terri1', numberMask: true, rotateNums: false, threedee: false, bgColor: 0x0D0A04, shapeColor: 0xFFFFFF, zoom: 1, mode: 0, shape: 'triangle', columns: 50, rows: 100, shapeWidth: 1, shapeHeight: 1, speed: 0.25, freq: 0.4, amp: 0.8, scale: 0.7, rainbow: false, regular: true, sticky: false, explosion: false, bigWarp: false},
    {name: 'terri2', numberMask: true, rotateNums: false, threedee: false, bgColor: 0x0D0A04, shapeColor: 0xFFFFFF, zoom: 1.25, mode: 0, shape: 'kid', columns: 80, rows: 140, shapeWidth: 1, shapeHeight: 1, speed: 0.25, freq: 1, amp: 0.1, scale: 0.9, rainbow: false, regular: true, sticky: false, explosion: false, bigWarp: false},
    {name: 'choppypete', numberMask: true, rotateNums: false, threedee: false, bgColor: 0x0D0A04, shapeColor: 0xFFFFFF, zoom: 1, mode: 0, shape: 'rounded', columns: 120, rows: 10, shapeWidth: 0.39, shapeHeight: 0.37, speed: 0.3, freq: 0.03, amp: 0.1, scale: 0.5, rainbow: false, regular: true, sticky: false, explosion: false, bigWarp: false},
    {name: 'choppyjoe', numberMask: true, rotateNums: false, threedee: false, bgColor: 0x0D0A04, shapeColor: 0xFFFFFF, zoom: 2, mode: 0, shape: 'rounded', columns: 20, rows: 115, shapeWidth: 0.73, shapeHeight: 0.4, speed: 0.15, freq: -0.75, amp: 0.5, scale: 1, rainbow: false, regular: true, sticky: true, explosion: false, bigWarp: false},
    {name: 'shattered', numberMask: true, rotateNums: false, threedee: false, bgColor: 0x0D0A04, shapeColor: 0xFFFFFF, zoom: 1, mode: 0, shape: 'triangle', columns: 20, rows: 94, shapeWidth: 0.61, shapeHeight: 1, speed: 0.1, freq: -0.4, amp: 1, scale: 0.68, rainbow: false, regular: true, sticky: false, explosion: true, bigWarp: false},
    {name: 'starliner', numberMask: true, rotateNums: false, threedee: false, bgColor: 0x0D0A04, shapeColor: 0xFFFFFF, zoom: 1.5, mode: 0, shape: 'square', columns: 250, rows: 1, shapeWidth: 0.4, shapeHeight: 1, speed: 0.15, freq: 1, amp: 1, scale: 1, rainbow: false, regular: true, sticky: false, explosion: false, bigWarp: false},
    {name: 'kiddo', numberMask: true, rotateNums: false, threedee: false, bgColor: 0x0D0A04, shapeColor: 0xFFFFFF, zoom: 2, mode: 0, shape: 'kid', columns: 30, rows: 30, shapeWidth: 1, shapeHeight: 1, speed: 0.05, freq: 0.5, amp: 0.5, scale: 0.5, rainbow: false, regular: false, sticky: false, explosion: false, bigWarp: true},
    {name: 'stitched', numberMask: true, rotateNums: false, threedee: false, bgColor: 0x0D0A04, shapeColor: 0xFFFFFF, zoom: 1, mode: 0, shape: 'triangle', columns: 250, rows: 250, shapeWidth: 0.6, shapeHeight: 0.6, speed: 0.15, freq: -0.10, amp: -0.2, scale: 0.2, rainbow: false, regular: true, sticky: false, explosion: false, bigWarp: false}
];

// Params
const PARAMS = {
    animate: true,
    numberMask: true,
    rotateNums: true,
    first: 1, 
    second: 8,
    threedee: false, 
    speed: 0.25,
    zoom: 1.0,
    bgColor: 0x111111,
    mode: 0,
    shape: 0,
    shapeColor: 0xFFFFFF,
    columns: 25,
    rows: 25,
    wide: 1,
    tall: 1,
    amp: 0.5,
    freq: 0.5,
    scale: 0.5,
    rainbow: false,
    regular: true,
    sticky: false,
    explosion: false,
    bigWarp: false,
    mouseRotateAmount: 0.125
  };
  
const buildPane = () => {
    pane = new Pane({title: 'Pattern Tweaks'});
    var folder = pane.addFolder({title: 'Basics'});
    folder.addInput(PARAMS, 'animate').on('change', ()=>{if(PARAMS.animate){tick();}});
    folder.addInput(PARAMS, 'threedee', {label: '3D'}).on('change', ()=>{buildPattern();});
    folder.addInput(PARAMS, 'numberMask').on('change', ()=>{
      firstBlade.hidden = !PARAMS.numberMask;
      secondBlade.hidden = !PARAMS.numberMask;
      pane.refresh();
      buildComposition();
    });
    folder.addInput(PARAMS, 'rotateNums', {title:'Rotate Nums'});
    const firstBlade = folder.addInput(PARAMS, 'first', {min:0,max:9,step:1}).on('change', ()=>{buildUpScene();});
    firstBlade.hidden = !PARAMS.numberMask;
    const secondBlade = folder.addInput(PARAMS, 'second', {min:0,max:9,step:1}).on('change', ()=>{buildUpScene();});
    secondBlade.hidden = !PARAMS.numberMask;
    folder.addInput(PARAMS, 'zoom', {min: 0.1, max: 2, step: 0.01}).on('change', ()=>{buildPattern();}); 
    folder.addInput(PARAMS, 'bgColor', {view:'color'}).on('change', ()=>{ tryFunc(()=>{ 
        renderer.setClearColor(new THREE.Color(PARAMS.bgColor));
    }) });
    folder = pane.addFolder({title: 'Mode'});
    folder.addInput(PARAMS, 'mode', {
      options: {
          grid: 0,
          storm: 1
      },
    }).on('change', ()=>{
      PARAMS.threedee = PARAMS.mode != 0;
      shapeColorBlade.hidden = PARAMS.rainbow;
      pane.refresh();
      patternMaterial.uniforms.uMode.value = PARAMS.mode;
      if(PARAMS.mode != 0) {
          PARAMS.regular = true;
          patternMaterial.uniforms.uRegular.value = PARAMS.regular ? 0.0 : 1.0;
      }
      buildUpScene();
    });
    folder.addInput(PARAMS, 'shape', {
      options: {
          square: 0,
          dot: 1,
          rounded: 2,
          triangle: 3,
          squiggle: 4,
          kid: 5,
      },
    }).on('change', ()=>{
      loadShapeTexture();
    });
    folder.addInput(PARAMS, 'wide', {label: 'shape width', min: 0, max: 1, step: 0.01}).on('change', ()=>{buildPattern();});
    folder.addInput(PARAMS, 'tall', {label: 'shape height', min: 0, max: 1, step: 0.01}).on('change', ()=>{buildPattern();});
    folder.addInput(PARAMS, 'speed', {min: 0.01, max: 1, step: 0.01}).on('change', ()=>{patternMaterial.uniforms.uSpeed.value = PARAMS.speed;});
    folder.addInput(PARAMS, 'freq', {min: -1, max: 1, step: 0.01}).on('change', ()=>{patternMaterial.uniforms.uFreq.value = remap(PARAMS.freq, -1, 1, -0.01, 0.01)});
    folder.addInput(PARAMS, 'amp', {min: -1, max: 1, step: 0.01}).on('change', ()=>{patternMaterial.uniforms.uAmp.value = PARAMS.amp});
    folder.addInput(PARAMS, 'scale', {min: 0, max: 1, step: 0.01}).on('change', ()=>{patternMaterial.uniforms.uScale.value = PARAMS.scale;});
    folder.addInput(PARAMS, 'rainbow').on('change', ()=>{
      patternMaterial.uniforms.uBow.value = PARAMS.rainbow ? 0.0 : 1.0;
      shapeColorBlade.hidden = PARAMS.rainbow;
      pane.refresh();
    });
    const shapeColorBlade = folder.addInput(PARAMS, 'shapeColor', {label:'color',view:'color'}).on('change', ()=>{tryFunc(()=>{ patternMaterial.uniforms.uColor.value = new THREE.Color(PARAMS.shapeColor); }) });
    shapeColorBlade.hidden = PARAMS.rainbow;
    const gridOptions = pane.addFolder({title: "Grid Options"});
    gridOptions.addInput(PARAMS, 'columns', {min: 1, max: 250, step: 1}).on('change', ()=>{buildPattern();});
    gridOptions.addInput(PARAMS, 'rows', {min: 1, max: 250, step: 1}).on('change', ()=>{buildPattern();});
    gridOptions.addInput(PARAMS, 'regular').on('change', ()=>{patternMaterial.uniforms.uRegular.value = PARAMS.regular ? 0.0 : 1.0;});
    gridOptions.addInput(PARAMS, 'sticky').on('change', ()=>{ patternMaterial.uniforms.uSticky.value = PARAMS.sticky ? 0.0 : 1.0; });
    gridOptions.addInput(PARAMS, 'explosion').on('change', ()=>{
        patternMaterial.uniforms.uExplosion.value = PARAMS.explosion ? 0.0 : 1.0;});
    gridOptions.addInput(PARAMS, 'bigWarp').on('change', ()=>{patternMaterial.uniforms.uBigWarp.value = PARAMS.bigWarp ? 0.0 : 1.0;});
};

export const remap=(value, vMin, vMax, mMin, mMax)=>{
    return mMin + (mMax - mMin) * (value - vMin) / (vMax - vMin);
}

// Shaders
const vertexShader = /*glsl*/`
    #define PI 3.14159265359
    uniform float uTime;
    uniform float uSpeed;
    uniform float uMode;
    uniform float uAmp;
    uniform float uFreq;
    uniform float uScale;
    uniform float uRegular;
    uniform float uSticky;
    uniform float uExplosion;
    uniform float uBigWarp;

    attribute vec3 translate;

    varying vec2 vUv;
    varying float vNoise;

    //	Simplex 3D Noise 
    //	by Ian McEwan, Ashima Arts
    //
    vec4 permute(vec4 x){return mod(((x*34.0)+1.0)*x, 289.0);}
    vec4 taylorInvSqrt(vec4 r){return 1.79284291400159 - 0.85373472095314 * r;}

    float snoise(vec3 v){ 
    const vec2  C = vec2(1.0/6.0, 1.0/3.0) ;
    const vec4  D = vec4(0.0, 0.5, 1.0, 2.0);

    // First corner
    vec3 i  = floor(v + dot(v, C.yyy) );
    vec3 x0 =   v - i + dot(i, C.xxx) ;

    // Other corners
    vec3 g = step(x0.yzx, x0.xyz);
    vec3 l = 1.0 - g;
    vec3 i1 = min( g.xyz, l.zxy );
    vec3 i2 = max( g.xyz, l.zxy );

    //  x0 = x0 - 0. + 0.0 * C 
    vec3 x1 = x0 - i1 + 1.0 * C.xxx;
    vec3 x2 = x0 - i2 + 2.0 * C.xxx;
    vec3 x3 = x0 - 1. + 3.0 * C.xxx;

    // Permutations
    i = mod(i, 289.0 ); 
    vec4 p = permute( permute( permute( 
                i.z + vec4(0.0, i1.z, i2.z, 1.0 ))
            + i.y + vec4(0.0, i1.y, i2.y, 1.0 )) 
            + i.x + vec4(0.0, i1.x, i2.x, 1.0 ));

    // Gradients
    // ( N*N points uniformly over a square, mapped onto an octahedron.)
    float n_ = 1.0/7.0; // N=7
    vec3  ns = n_ * D.wyz - D.xzx;

    vec4 j = p - 49.0 * floor(p * ns.z *ns.z);  //  mod(p,N*N)

    vec4 x_ = floor(j * ns.z);
    vec4 y_ = floor(j - 7.0 * x_ );    // mod(j,N)

    vec4 x = x_ *ns.x + ns.yyyy;
    vec4 y = y_ *ns.x + ns.yyyy;
    vec4 h = 1.0 - abs(x) - abs(y);

    vec4 b0 = vec4( x.xy, y.xy );
    vec4 b1 = vec4( x.zw, y.zw );

    vec4 s0 = floor(b0)*2.0 + 1.0;
    vec4 s1 = floor(b1)*2.0 + 1.0;
    vec4 sh = -step(h, vec4(0.0));

    vec4 a0 = b0.xzyw + s0.xzyw*sh.xxyy ;
    vec4 a1 = b1.xzyw + s1.xzyw*sh.zzww ;

    vec3 p0 = vec3(a0.xy,h.x);
    vec3 p1 = vec3(a0.zw,h.y);
    vec3 p2 = vec3(a1.xy,h.z);
    vec3 p3 = vec3(a1.zw,h.w);

    //Normalise gradients
    vec4 norm = taylorInvSqrt(vec4(dot(p0,p0), dot(p1,p1), dot(p2, p2), dot(p3,p3)));
    p0 *= norm.x;
    p1 *= norm.y;
    p2 *= norm.z;
    p3 *= norm.w;

    // Mix final noise value
    vec4 m = max(0.6 - vec4(dot(x0,x0), dot(x1,x1), dot(x2,x2), dot(x3,x3)), 0.0);
    m = m * m;
    return 42.0 * dot( m*m, vec4( dot(p0,x0), dot(p1,x1), 
                                    dot(p2,x2), dot(p3,x3) ) );
    }

    float noise(vec3 tr,float t) {
        vec3 trTime = vec3(tr.x + t,tr.y + t,tr.z + t);
        return sin( trTime.x * 2.1 ) + sin( trTime.y * 3.2 ) + sin( trTime.z * 4.3 );
    }

    vec2 rotate2D(vec2 st, float a) {
        st -= 0.5;
        st = mat2(cos(a),-sin(a),
                  sin(a),cos(a))*st;
        st += 0.5;
        return st;
    }

    float map(float value, float inMin, float inMax, float outMin, float outMax) {
        return outMin + (outMax - outMin) * (value - inMin) / (inMax - inMin);
    }

    void main()
    {
        vec4 mvPosition = modelViewMatrix * vec4( translate, 1.0 );
        float noise = (uMode == 0. ? snoise(vec3((translate.xy*uFreq),uTime*uSpeed)) : noise(translate * uFreq,uTime*uSpeed));

        //rotate individuals
        vec2 pos = position.xy;
        (uMode== 0. ? mvPosition.xy += rotate2D(pos.xy,noise*uAmp*PI) : pos.xy += 0.); 
        (uMode== 0. && uBigWarp == 0. ? mvPosition.xy += rotate2D(mvPosition.xy,noise*uAmp*noise*uAmp*20.) : mvPosition.xy += 0.); 

        // float scale = (uMode == 0. ? uScale * map(noise,0.,1.,0.001,0.9) : noise * uScale * 20.0 + 10.0);
        // float scale = (uMode == 0. ? noise*uScale : noise * uScale * 20.0 + 10.0);
        float scale = (uMode == 0. ? map(noise*uScale, 0., 1., 0.1,10.) : noise * uScale * 20.0 + 10.0);

        mvPosition.xyz += (uRegular == 0. ? position * scale : position);
        mvPosition.xyz *= (uSticky == 0. ? (step(scale,0.01) < 0.01 ? 0. : step(scale,0.01)) : 1.);
        mvPosition.xyz *= (uMode == 0. && uExplosion == 0. ? scale : 1.);

        gl_Position = projectionMatrix * mvPosition;
        
        vNoise = noise;
        vUv = uv;
    }
`;

const fragmentShader = /*glsl*/`
    uniform sampler2D uMap;
    uniform float uMode;
    uniform vec3 uColor;
    uniform float uBow;

    varying vec2 vUv;
    varying float vNoise;

    // HSL to RGB Convertion helpers
    vec3 HUEtoRGB(float H){
        H = mod(H,1.0);
        float R = abs(H * 6.0 - 3.0) - 1.0;
        float G = 2.0 - abs(H * 6.0 - 2.0);
        float B = 2.0 - abs(H * 6.0 - 4.0);
        return clamp(vec3(R,G,B),0.0,1.0);
    }

    vec3 HSLtoRGB(vec3 HSL){
        vec3 RGB = HUEtoRGB(HSL.x);
        float C = (1.0 - abs(2.0 * HSL.z - 1.0)) * HSL.y;
        return (RGB - 0.5) * C + HSL.z;
    }

    void main() {
        vec4 diffuseColor = texture2D( uMap, vUv );
        gl_FragColor = (uBow != 1. ? vec4( diffuseColor.xyz * HSLtoRGB(vec3(vNoise/2.5, 1.0, 0.5)), diffuseColor.w ) : vec4(diffuseColor.xyz * uColor, diffuseColor.w));
        if(diffuseColor.w < 0.95) discard;
    }
`;

// Material
const patternMaterial = new THREE.ShaderMaterial({
    vertexShader: vertexShader,
    fragmentShader: fragmentShader,
    transparent: true,
    uniforms: {
        'uMap': { value: null },
        'uTime': { value: 0.0 },
        'uSpeed': {value: 1.0 },
        'uMode': {value: PARAMS.mode},
        'uColor': {value: new THREE.Color(PARAMS.shapeColor)},
        'uBow': {value: PARAMS.rainbow ? 0.0 : 1.0},
        'uAmp': {value: PARAMS.amp},
        'uFreq': {value: PARAMS.freq},
        'uScale': {value: PARAMS.scale},
        'uRegular': {value: PARAMS.regular ? 0.0 : 1.0},
        'uSticky': {value: PARAMS.sticky ? 0.0 : 1.0},
        'uExplosion': {value: PARAMS.explosion ? 0.0 : 1.0},
        'uBigWarp': {value: PARAMS.bigWarp ? 0.0 : 1.0},
    }
});

// Scene
const scene = new THREE.Scene();

// Loaders
const textureLoader = new THREE.TextureLoader();
const gltfLoader = new GLTFLoader();
var patternMesh;
var passes = [];
var maskMeshes = [];
var maskGroup = new THREE.Group();
var maskScene = new THREE.Scene();
maskScene.add(maskGroup);
var patternScene = new THREE.Scene();
var shapeTextures = [new URL('../fx/square.png', import.meta.url), 
                    new URL('../fx/circle.png', import.meta.url), 
                    new URL('../fx/rounded.png', import.meta.url), 
                    new URL('../fx/triangle.png', import.meta.url),
                    new URL('../fx/squiggle.png', import.meta.url),
                    new URL('../fx/kid.png', import.meta.url)];
var numberModels = [new URL('../fx/num-0.glb', import.meta.url), 
                    new URL('../fx/num-1.glb', import.meta.url), 
                    new URL('../fx/num-2.glb', import.meta.url), 
                    new URL('../fx/num-3.glb', import.meta.url), 
                    new URL('../fx/num-4.glb', import.meta.url), 
                    new URL('../fx/num-5.glb', import.meta.url), 
                    new URL('../fx/num-6.glb', import.meta.url), 
                    new URL('../fx/num-7.glb', import.meta.url), 
                    new URL('../fx/num-8.glb', import.meta.url), 
                    new URL('../fx/num-9.glb', import.meta.url)];
var celebrateTexture = new URL('../fx/confet-white.png', import.meta.url);
var waitTexture = new URL('../fx/wait-white.png', import.meta.url);

const loadShapeTexture=()=>{
    textureLoader.load(shapeTextures[PARAMS.shape], function(tex) {
        patternMaterial.uniforms.uMap.value = tex;
    });
}

const buildUpScene=()=>{
    maskMeshes.forEach((m)=>{
        maskGroup.remove(m.mesh);
    });
    maskMeshes=[];

    loadShapeTexture();
    if(isCelebratory){
        textureLoader.load(celebrateTexture, function(tex) {
            buildCelebrateMask(tex);
            buildPattern();
            buildComposition();
        });
    }
    else if(isFarAway){
        textureLoader.load(waitTexture, function(tex) {
            buildCelebrateMask(tex);
            buildPattern();
            buildComposition();
        });
    }
    else {
        gltfLoader.load(numberModels[PARAMS.first].href, function(num1) {
            gltfLoader.load(numberModels[PARAMS.second].href, function(num2) {
                const obj1 = new Object();
                obj1.mesh = num1.scene.children[0];
                maskMeshes.push(obj1);
                const obj2 = new Object();
                obj2.mesh = num2.scene.children[0];
                maskMeshes.push(obj2);
    
                var boundingBox = new THREE.Box3();
                boundingBox.setFromObject(obj1.mesh);
                const size1 = boundingBox.getSize(new THREE.Vector3());
                boundingBox = new THREE.Box3();
                boundingBox.setFromObject(obj2.mesh);
                const size2 = boundingBox.getSize(new THREE.Vector3());
                // console.log(`${PARAMS.first}-s1.x: ${size1.x}, s1.y: ${size1.y}, ${PARAMS.second}-s2.x: ${size2.x}, s2.y: ${size2.y}`);
                obj1.x = 0;
                const padding = PARAMS.first == 1 ? 0.3 : 0.055;
                const secondOffset = (size1.x + padding);
                obj2.x = obj1.x + secondOffset;
                
                obj1.y = 0;
                obj2.y = -size2.y*0.7;
    
                buildMask();
                buildPattern();
                buildComposition();
            });
        });
    }
};

const buildMask=()=>{
    maskMeshes.forEach((mask)=>{
        var mesh = mask.mesh;
        mesh.material = new THREE.MeshBasicMaterial({color: 0xffffff});
        mesh.position.set(mask.x,mask.y,0);
        maskGroup.add(mesh);   
    });
    var boundingBox = new THREE.Box3();
    boundingBox.setFromObject(maskGroup);
    const size = boundingBox.getSize(new THREE.Vector3());
    const d = Math.min(sizes.width, sizes.height);
    numDimension = Math.max(size.x, size.y);
    const f = d/numDimension;
    // console.log(`d ${d}, s ${numDimension}, d/s ${f}`)
    maskGroup.scale.set(f,f,1);
    maskGroup.position.set(-f*0.25,f*0.25,0);
    renderCore();
}

const buildCelebrateMask=(tex)=>{
    const dimension = Math.min(sizes.width, sizes.height)*0.75;
    const geom = new THREE.PlaneGeometry(dimension, dimension, 64, 64);
    // const mat = new THREE.MeshBasicMaterial({color: PARAMS.bgColor, map: tex, alphaMap: tex, transparent: true});
    const mat = new THREE.MeshBasicMaterial({alphaMap: tex, alphaTest: 0.5});
    mat.transparent = true;
    const mesh = new THREE.Mesh( geom, mat );
    maskGroup.add(mesh);
    renderCore();
}

var cellSize = 1;
const buildPattern=()=>{

    /**
     * Geometry and mesh
     */
    const dimension = Math.max(sizes.width, sizes.height) * PARAMS.zoom;
    const w = dimension/PARAMS.columns;
    const padw = w*(1-PARAMS.wide);
    const h = dimension/PARAMS.rows;
    const padh = h*(1-PARAMS.tall);
    const gw = w-padw;
    const gh = h-padh;
    cellSize = Math.max(w, h);
    const geom = new THREE.PlaneGeometry(gw, gh);

    const geometry = new THREE.InstancedBufferGeometry();
    geometry.index = geom.index;
    geometry.attributes = geom.attributes;

    const particleCount = PARAMS.columns * PARAMS.rows;

    const translateArray = new Float32Array( particleCount * 3 );

    for ( let i = 0, i3 = 0; i < particleCount; i ++, i3 += 3 ) {
        if(PARAMS.threedee){
            translateArray[ i3 + 0 ] = (Math.random() * 2 - 1) * dimension;
            translateArray[ i3 + 1 ] = (Math.random() * 2 - 1) * dimension;
            translateArray[ i3 + 2 ] = (Math.random() * 2 - 1) * dimension;
        }
        else {
            const col = i % PARAMS.columns;
            const row = Math.floor(i/PARAMS.columns);
            const x = (col*w+w)-(dimension*0.5+w*0.5);
            const y = (row*h+h)-(dimension*0.5+h*0.5);
            translateArray[ i3 + 0 ] = x;
            translateArray[ i3 + 1 ] = y;
            translateArray[ i3 + 2 ] = 0;
        }

    }

    if(!patternMesh){
        patternMesh = new THREE.Mesh( geometry, patternMaterial );
        patternScene.add(patternMesh);
        renderCore();
    }
    else {
        patternMesh.geometry = geometry;
    }
    patternMesh.rotation.x = 0;
    patternMesh.rotation.y = 0;
    patternMesh.geometry.setAttribute( 'translate', new THREE.InstancedBufferAttribute( translateArray, 3 ) );
    patternMesh.geometry.needsUpdate = true;
    patternMaterial.needsUpdate = true;
}

const buildComposition=()=>{

    passes.forEach((pass)=>{
        composer.removePass(pass);
    });
    passes=[];
    composer.reset(renderTarget);

    const p1 = new ClearPass();
    composer.addPass(p1);

    if(PARAMS.numberMask){
        const p2 = new MaskPass(maskScene, camera); 
        composer.addPass(p2);
    }
    else{
        resetNumsRotation();
    }

    const p3 = new RenderPass(patternMesh, camera);
    p3.clear = false;
    composer.addPass(p3);

    const p4 = new ClearMaskPass();
    composer.addPass(p4);

    const p5 = new ShaderPass(CopyShader);
    composer.addPass(p5);

    renderCore();
}

/**
 * Camera
 */
// Base camera
const buildCamera=()=>{
    const max = Math.max(sizes.width, sizes.height);
    camera = new THREE.OrthographicCamera(-sizes.width/2,sizes.width/2,sizes.height/2,-sizes.height/2,-max,max);
    camera.zoom = 1;
}

/**
 * Renderer
 */
const buildRenderer = () =>{
    renderer = new THREE.WebGLRenderer({
        canvas: canvas,
    });
    renderer.setSize(sizes.width, sizes.height, false);
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
    renderer.setClearColor(new THREE.Color(PARAMS.bgColor));
    
    var parameters = {
        minFilter: THREE.LinearFilter,
        magFilter: THREE.LinearFilter,
        format: THREE.RGBAFormat,
        stencilBuffer: true,
    }
    renderTarget = new THREE.WebGLRenderTarget(sizes.width,sizes.height,parameters);
    composer = new EffectComposer(renderer, renderTarget);
};

/**
 * Animate
 */
let clock = new THREE.Clock();
const tick=()=>{
    if(patternMesh){
        const elapsedTime = clock.getElapsedTime();
        patternMaterial.uniforms.uTime.value = elapsedTime;
        if(PARAMS.threedee){
            patternMesh.rotation.x = elapsedTime * 0.2;
            patternMesh.rotation.y = elapsedTime * 0.4;
        }
    }

    renderCore();

    if(PARAMS.animate) { window.requestAnimationFrame(tick); }
};

const renderCore=()=>{
    if(composer){
        renderer.clear();
        composer.render();
    }
    // else {
    //     renderer.render(scene, camera);
    // }
}

window.addEventListener('resize', () =>
{
    if(!canvas){return;}
    sizes.width = canvas.clientWidth;
    sizes.height = canvas.clientHeight;
    
    camera.left  = -sizes.width/2
    camera.right = sizes.width/2;
    camera.top = sizes.height/2;
    camera.bottom = -sizes.height/2;
    camera.aspect = sizes.width / sizes.height;
    const max = Math.max(sizes.width, sizes.height);
    camera.near = -max;
    camera.far = max;
    camera.updateProjectionMatrix();

    renderer.setSize(sizes.width, sizes.height, false);
    composer.setSize(sizes.width, sizes.height);

    if(!isCelebratory && !isFarAway){
        const d = Math.min(sizes.width, sizes.height);
        const f = d/numDimension;
        maskGroup.scale.set(f,f,1);
        maskGroup.position.set(-f*0.25,f*0.25,0);
    }

    buildPattern();
});

window.addEventListener('pointermove', (e)=>{
    if(!PARAMS.rotateNums || !sizes){return;}
    const x = (Math.min(e.clientX, window.innerWidth)/window.innerWidth)*2-1;
    const y = (Math.min(e.clientY, window.innerHeight)/window.innerHeight)*2-1;
    gsap.to(maskGroup.rotation, {y: Math.PI*PARAMS.mouseRotateAmount*x, ease:'power2.inout', duration:2});
    gsap.to(maskGroup.rotation, {x: Math.PI*PARAMS.mouseRotateAmount*y, ease:'power2.inout', duration:2});
});

const resetNumsRotation=()=>{
    gsap.to(maskGroup.rotation, {y: 0, ease:'power2.inout', duration:1.25});
    gsap.to(maskGroup.rotation, {x: 0, ease:'power2.inout', duration:1.25});
}

let mouseDown = false;
window.addEventListener('pointerdown', ()=>{
    mouseDown = true;
});

window.addEventListener('pointerup', ()=>{
    mouseDown = false;
});

document.addEventListener('keydown', (e)=>{
    if(e.ctrlKey && e.key === "e") { pane.containerElem_.style.display = pane.containerElem_.style.display === 'none' ? 'block' : 'none'; }
});

const tryFunc=(func)=>{
    if(mouseDown) {setTimeout(()=>{if(!mouseDown){func();}}, 100);}
};

const randomInt=(min, max)=>{ 
    return Math.floor(Math.random() * (max - min) + min);
} 

export function initializeFX(sourceCanvas, firstNum, secondNum, showPane, presetName, celebrate, farAway){
    isCelebratory = celebrate;
    isFarAway = farAway;
    canvas = sourceCanvas;
    sizes = {
        width: canvas.clientWidth,
        height: canvas.clientHeight
    };
    if(!presetName){
        PARAMS.numberMask = false;
        PARAMS.threedee = false;
        PARAMS.bgColor = '#111111';
        PARAMS.shapeColor = '#ffffff';
        PARAMS.zoom = 1;
        PARAMS.mode = 0;
        PARAMS.shape = randomInt(0,shapeNames.length-1);
        PARAMS.columns = randomInt(5,101);
        PARAMS.rows = randomInt(5,101);
        PARAMS.wide = remap(Math.random(), 0, 1, 0.1, 1);
        PARAMS.tall = remap(Math.random(), 0, 1, 0.1, 1);
        PARAMS.speed = remap(Math.random(), 0, 1, 0.01, 0.5);
        PARAMS.amp = remap(Math.random(), 0, 1, -1, 1);
        PARAMS.freq = remap(Math.random(), 0, 1, -1, 1);
        PARAMS.scale = Math.random();
        PARAMS.rainbow = (isCelebratory ? isCelebratory : Math.random() <= 0.3);
        PARAMS.regular = Math.random() <= 0.5;
        PARAMS.sticky = Math.random() <= 0.5;
        PARAMS.explosion = Math.random() <= 0.15;
        PARAMS.bigWarp = Math.random() <= 0.15;
    }
    else {
        if(presetName == 'random'){
            const options = presets.filter(p => p.numberMask == true);
            presetName = options[randomInt(0,options.length)].name; 
        }
        else if(presetName == 'randomFullScreen'){
            const options = presets.filter(p => p.numberMask == false);
            presetName = options[randomInt(0,options.length)].name; 
        }
        const preset = presets.filter(p => p.name == presetName)[0];
        const shapeIndex = shapeNames.indexOf(preset.shape);
        PARAMS.numberMask = preset.numberMask;
        PARAMS.rotateNums = preset.rotateNums;
        PARAMS.threedee = preset.threedee;
        PARAMS.bgColor = preset.bgColor;
        PARAMS.shapeColor = preset.shapeColor;
        PARAMS.zoom = preset.zoom;
        PARAMS.mode = preset.mode;
        PARAMS.shape = shapeIndex;
        PARAMS.columns = preset.columns;
        PARAMS.rows = preset.rows;
        PARAMS.wide = preset.shapeWidth;
        PARAMS.tall = preset.shapeHeight;
        PARAMS.speed = preset.speed;
        PARAMS.amp = preset.amp;
        PARAMS.freq = preset.freq;
        PARAMS.scale = preset.scale;
        PARAMS.rainbow = (isCelebratory ? isCelebratory : preset.rainbow);
        PARAMS.regular = preset.regular;
        PARAMS.sticky = preset.sticky;
        PARAMS.explosion = preset.explosion;
        PARAMS.bigWarp = preset.bigWarp;
    };
    patternMaterial.uniforms.uSpeed.value = PARAMS.speed;
    patternMaterial.uniforms.uColor.value = new THREE.Color(PARAMS.shapeColor);
    patternMaterial.uniforms.uBow.value = PARAMS.rainbow ? 0.0 : 1.0;
    patternMaterial.uniforms.uAmp.value = PARAMS.amp;
    patternMaterial.uniforms.uFreq.value = remap(PARAMS.freq, -1, 1, -0.01, 0.01);
    patternMaterial.uniforms.uScale.value = PARAMS.scale;
    patternMaterial.uniforms.uRegular.value = PARAMS.regular ? 0.0 : 1.0;
    patternMaterial.uniforms.uSticky.value = PARAMS.sticky ? 0.0 : 1.0;
    patternMaterial.uniforms.uExplosion.value = PARAMS.explosion ? 0.0 : 1.0;
    patternMaterial.uniforms.uBigWarp.value = PARAMS.bigWarp ? 0.0 : 1.0;
    patternMaterial.needsUpdate = true;
    if(firstNum == null) {
        firstNum = randomInt(0,4);
        secondNum = randomInt(0,10);
    };
    initializeFXCore(firstNum,secondNum);
    buildPane();
    pane.containerElem_.style.display = showPane ? 'block' : 'none';
};

const initializeFXCore=(firstNum, secondNum)=> {
    PARAMS.first = firstNum;
    PARAMS.second = secondNum;
    buildCamera();
    buildRenderer();
    buildUpScene();
    tick();
}